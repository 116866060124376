import React, { Component } from 'react'
import Layout from 'components/layout'
import Pagination from 'components/pagination'
import PaddedSlideshow from 'components/slideshows/paddedSlideshow'
import Title from 'components/title'
import { dateFormatted, trFunction } from 'utils/functions'
import { graphql } from 'gatsby'

class Press extends Component {
  render() {
    const locale = this.props.data.site.siteMetadata.locale
    const translations = this.props.data.translations
    const tr = (code) => trFunction(code, translations, locale)
    const allPress = this.props.data.allPress.edges
    const thisPress = this.props.data.thisPress
    const thisPage = this.props.data.thisPage

    return (
      <Layout>
        <div id="content">
          <Title
            subtitle={thisPage.title}
            subtitleLink="/press"
            title={thisPress.title}
          />
          {thisPress.slides && <PaddedSlideshow slides={thisPress.slides} />}
          <section className="page-content wrap">
            <div
              className="small-caps date header-date"
              style={{ textAlign: 'center' }}
            >
              {dateFormatted(thisPress.datePosted, locale)}
            </div>
            <div className="wrap-text-large centered strip-p single-commons">
              {thisPress.content && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: thisPress.content.childMarkdownRemark.html,
                  }}
                />
              )}
            </div>
            <Pagination
              slug={thisPage.slug}
              list={allPress}
              item={thisPress}
              label={tr('ARTICLE')}
              data={this.props.data}
            />
          </section>
        </div>
      </Layout>
    )
  }
}
export default Press

export const pressQuery = graphql`
  query ($slug: String) {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    allPress: allContentfulPress(sort: { fields: [datePosted], order: DESC }) {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
    thisPress: contentfulPress(slug: { eq: $slug }) {
      id
      title
      slug
      datePosted
      slides {
        id
        images {
          title
          ...GatsbyImage
        }
        caption
        highlightPoints {
          title
          caption
          triggerPositionLeft
          triggerPositionTop
          popupAlign
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    thisPage: contentfulPages(slug: { eq: "press" }) {
      title
      slug
    }
  }
`
